@if (value && value.length) {
  <div class="multiple-string-input_strings">
    @for (string of value; track string; let id = $index) {
      <div>
        <span>{{ string }}</span>
        <img
          [appFocusable]
          (click)="removeString(id)"
          appAssetSrc="assets/svg/common/cross-blue.svg"
        />
      </div>
    }
  </div>
}
<div class="multiple-string-input_input">
  <input
    #textInput
    [disabled]="disabled"
    type="text"
    [(ngModel)]="inputValue"
    (blur)="onBlur()"
    (keydown)="onInputKeydown($event)"
  />
</div>
