import {
  Component,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { type ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

@Component({
  selector: 'multiple-string-input',
  templateUrl: './multiple-string-input.component.html',
  styleUrls: ['./multiple-string-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleStringInputComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [FocusableDirective, AssetSrcDirective, FormsModule],
})
export class MultipleStringInputComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @ViewChild('textInput') input: ElementRef;
  public inputValue = '';
  private _value: string[] = [];

  public get value(): string[] {
    return this._value;
  }

  public set value(val: string[]) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  @HostBinding('class.focused')
  public get isFocused(): boolean {
    if (!this.input) {
      return false;
    }
    return document.activeElement === this.input.nativeElement;
  }

  @HostListener('click')
  public focusInput(): void {
    this.input.nativeElement.focus();
  }

  public onInputKeydown(event: KeyboardEvent): void {
    if (event.code === 'Comma' || event.code === 'Space' || event.code === 'Enter') {
      event.preventDefault();
      this.value = [...this.value, this.inputValue];
      this.inputValue = '';
    }
  }

  public removeString(index: number): void {
    if (this.disabled) {
      return;
    }

    this.value = [...this.value.slice(0, index), ...this.value.slice(index + 1)];
  }

  public writeValue(value: string[]): void {
    this.value = value;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onBlur(): void {
    if (this.inputValue) {
      this.value = [...this.value, this.inputValue];
      this.inputValue = '';
    }
  }

  private onChange = (val: string[]) => {};

  private onTouched = () => {};
}
